import React from 'react'
import styled from 'styled-components'
const StyledDiv = styled.div`
width: 100%;
background:transparent;
display:flex;
justify-content:center;
align-items:center;

.textSection{
width: 70%;
font-size: 1.3em;
list-style: none;
text-align: center !important;
font-weight: 400;
font-family: "Harmonia Sans", 'Nunito Sans', Arial, sans-serif;
line-height: 1.6;
color: #002500;
background:transparent;
padding: 50px 50px;
}
@media (max-width: 769px){
    .textSection{
        width: 100%;
        padding: 20px 10px;
    }
}
`

const TextSection = () =>{
    return(
        <StyledDiv>
            <div className="textSection">
            The ProLon Fasting Mimicking Diet® (FMD) can transform you and bring about real healthy change, both inside and out. You can lose weight and belly fat, rejuvenate your cells to support healthy aging, and kickstart healthy eating habits*.
            </div>
        </StyledDiv>
    )
} 

export default TextSection